<script>
    import Layout from "../../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Monitor from "@/services/Monitor";
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
    import _ from 'underscore';

    export default {
        components: { Layout, PageHeader},
        page: {
            title: "API Errors",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "CRON Log",
                items: [
                    {
                        text: "Logs",
                    },
                    {
                        text: "CRON Log",
                        active: true,
                    },
                ],
                isIntervalOn: true,
                selectedTenant: '',
                tenantList: [], 
                originalCronLogs: [], 
                isBusy: false,
                queuesData: {},
                showLoader:false,
                error: '',
                tableData: [],
                totalRows: 0,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "timestamp",
                sortDesc: true,
                fields: [
                    {
                        key: "id",
                        label: "ID",
                        sortable: true
                    },
                    {
                        key: "cron",
                        label: "Cron",
                        sortable: false
                    },
                    {
                        key: "tenant",
                        label: "Tenant",
                        sortable: true
                    },
                    {
                        key: "status",
                        label: "Status",
                        sortable: true
                    },
                    {
                        key: "arguments",
                        label: "Arguments",
                        sortable: false
                    },
                    {
                        key: "response",
                        label: "Response",
                        sortable: false
                    },
                    {
                        key: "start_at",
                        label: "Start At",
                        sortable: true
                    },
                    {
                        key: "end_at",
                        label: "End At",
                        sortable: true
                    },
                    {
        key: "time_difference",
        label: "Time",
        sortable: false
    },
                    "action",
                ]
            };
        },
        destroyed() {
            clearInterval(this.timer);
        },
        middleware: "authentication",
        async mounted() {
            try {
                await this.getCronLogs();
            } catch (error) {
                console.error('Error during component initialization:', error);
            }
        },
        methods: {
            getTimeDifference(startAt, endAt) {
        if (!startAt || !endAt) return '';
        
        const startTime = new Date(startAt);
        const endTime = new Date(endAt);
        const timeDiff = (endTime - startTime) / 1000; 

        const hours = Math.floor(timeDiff / 3600);
        const minutes = Math.floor((timeDiff % 3600) / 60);
        const seconds = Math.floor(timeDiff % 60);

        return `${hours}h ${minutes}m ${seconds}s`;
    },

async getCronLogs(tenant) {
            this.toggleBusy(); 
            try {
                const response = await Monitor.getCronLogs(); 
                this.originalCronLogs = response.data.data; 

            // Map tenant list correctly
            const tenants = [...new Set(this.originalCronLogs.map(item => item.tenant))];
            this.tenantList = tenants.map((tenant, index) => ({ id: index, text: tenant }));

            if (tenant) {
              this.filterTableData(tenant);
            } else {
              this.tableData = this.originalCronLogs;
            }
            this.totalRows = this.tableData.length;
          } catch (error) {
            this.error = error.response?.data?.error || "Error fetching data";
            this.tableData = [];
            this.totalRows = 0;
          } finally {
            this.toggleBusy();
          }
        },

        onTenantSelect() {
          this.filterTableData(this.selectedTenant);
        },

        filterTableData(tenant) {
          if (tenant) {
            this.tableData = this.originalCronLogs.filter(item => item.tenant === tenant);
          } else {
            this.tableData = this.originalCronLogs;
          }
          this.totalRows = this.tableData.length;
        },
      }


    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

    <div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="custom-accordion">
                    <a class="text-body fw-semibold pb-2 d-block" data-toggle="collapse"
                        href="javascript: void(0);" role="button" aria-expanded="false"
                        v-b-toggle.filters-collapse>
                    </a>
                    <b-collapse visible id="filters-collapse">
                        <div class="card p-2 shadow-none">
                            <div class="row align-items-center">
                                <div class="col-sm-12 col-md-6 d-flex align-items-center">
                                    <b-form-group label="Tenant" label-for="formrow-tenant-input" class="mb-0 me-3">
                                      <select
                                          id="formrow-tenant-input"
                                          v-model="selectedTenant"
                                          @change="onTenantSelect"
                                          class="form-select"
                                      >
                                        <option value="" disabled>Select tenant...</option>
                                        <option v-for="tenant in tenantList" :key="tenant.id" :value="tenant.text">
                                          {{ tenant.text }}
                                        </option>
                                      </select>
                                    </b-form-group>
                                </div>

                            </div>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </div>
             <div class="card">
                    <div class="card-body" v-if="selectedTenant">
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm"
                                            :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                           
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                                            class="form-control form-control-sm ms-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                        
                        </div>

                        <div class="table-responsive mb-0">
                            <b-table
                                :busy="isBusy"
                                :items="tableData"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                show-empty
                                empty-text="No Data Found">

                                <template #table-busy>
                                    <div class="text-center text-black my-2">
                                        <b-spinner class="align-middle text-primary"></b-spinner>
                                        <strong class="text-primary">Loading...</strong>
                                    </div>
                                </template>

                                <template v-slot:cell(arguments)="tableData">
                                    <div class="d-flex align-items-center">
                                        <span v-if="tableData.item.arguments && tableData.item.arguments.length > 30" class="text-nowrap">
                                            {{ tableData.item.arguments.slice(0, 30) + '...' }}
                                        </span>
                                        <a 
                                            v-if="tableData.item.arguments && tableData.item.arguments.length > 30"
                                            v-b-popover.hover.html.bottom="tableData.item.arguments"
                                            class="elementPointed ms-2"
                                            title="Arguments"
                                            size="sm"
                                        >
                                            <i class="uil uil-eye font-size-18"></i>
                                        </a>
                                        <span v-else>{{ tableData.item.arguments }}</span>
                                    </div>
                                </template>

                                <template v-slot:cell(response)="tableData">
                                    <div class="d-flex align-items-center">
                                        <span v-if="tableData.item.response && tableData.item.response.length > 30" class="text-nowrap">
                                            {{ tableData.item.response.slice(0, 30) + '...' }}
                                        </span>
                                        <a
                                            v-if="tableData.item.response && tableData.item.response.length > 30"
                                            v-b-popover.hover.html.bottom="tableData.item.response"
                                            class="elementPointed ms-2"
                                            title="Response"
                                            size="sm"
                                        >
                                            <i class="uil uil-eye font-size-18"></i>
                                        </a>
                                        <span v-else>{{ tableData.item.response }}</span>
                                    </div>
                                </template>
                                <template v-slot:cell(time_difference)="data">
                                <span>{{ getTimeDifference(data.item.start_at, data.item.end_at) }}</span>
                            </template>

                            </b-table>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm-12 col-md-5">
                                <div class="dataTables_info">
                                    Showing {{ (currentPage - 1) * perPage + 1 }} to
                                    {{ (currentPage - 1) * perPage + totalRows  }} of {{ totalRows }} entries
                                </div>
                            </div>
                          <div class="row">
                            <div class="col">
                              <div class="dataTables_paginate paging_simple_numbers float-end">
                                <ul class="pagination pagination-rounded mb-0">
                                  <!-- pagination -->
                                  <b-pagination
                                      v-model="currentPage"
                                      :total-rows="totalRows"
                                      :per-page="perPage"
                                  ></b-pagination>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div v-else class="text-center mt-4 p-4">
                        <strong>Use the filter to display the data</strong>
                    </div>
                </div>
            </div>
</div>
        </Layout>
</template>